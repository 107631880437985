export function scrollToPageTop(behavior: ScrollBehavior = 'instant') {
	document.documentElement.scrollTo({ top: 0, behavior });
}

export function scrollToElementTop(
	element: HTMLElement,
	behavior: ScrollBehavior = 'instant'
) {
	element.scrollTo({ top: 0, behavior });
}
